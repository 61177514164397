<template>
  <v-sheet>
    League teams
  </v-sheet>
</template>

<script>
export default {
  data: () => ({
    
  })
}
</script>